
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Discount Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      tableData: [],
      cardData: [],

      title: "Discount ",
      items: [
        {
          text: "View",
        },
        {
          text: "Discount",
          active: true,
        },
      ],
      restaurants: [],
      companyID:0,
      branches: [],
      restaurantID: "",
      menuTabArr: [],
      menuTabType: "",
      branchID: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "discountTypeName",

          sortable: true,
        },
        {
          key: "couponCode",

          sortable: true,
        },
        {
          key: "discountInRs",

          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "discountPercent",

          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "minOrderValue",

          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "menuType",

          sortable: true,
        },
        {
          key: "validityPeriod",
          sortable: true,
        },
        {
          key: "Validity",

          sortable: true,
        },
        {
          key: "branchCount",

          sortable: true,
          tdClass: "align-right",
        },
        // {
        //   key: "createdBy",

        //   sortable: true,

        // },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    //this.getCategoryList();
    this.getRestaurantList();
    this.getMenuTabType();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    downloadSample() {
      this.axios
        .post(this.$loggedRole+"/exportExcel",
          {
            exportData: this.tableData,
            page: "DiscountCoupon",
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "discount-coupon.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },

    getAllDiscountByRestID() {
      this.axios
        .post(this.$loggedRole+"/getAllDiscountByRestID", {
          restID: this.restaurantID.restID,
          empTypeID: this.$storageData.profile.empTypeID,
          menuTabType: this.menuTabType.enum,
        })
        .then((response) => {
          //Then injecting the result to datatable parameters.

          this.tableData = response.data.data;
          this.cardData = response.data.cardData;
          //console.log((response));
        });
    },
    getAllDiscount() {
      this.getAllDiscountByRestID();
    },

    getRestaurantList() {
      var companyID = this.companyID;
      if(this.$storageData.login_type == 2 || this.$storageData.profile.empTypeID == 11)
      {
        companyID = this.$storageData.profile.companyID;
      }

      this.axios
        .post(this.$loggedRole+"/getRestaurantList", {
          companyID: companyID,
          restID: this.$storageData.login_type == 2 && this.$storageData.profile.empTypeID != 11 ? this.$storageData.profile.restID : 0,
          restBranchID: this.$storageData.login_type == 2 && this.$storageData.profile.empTypeID != 11 ? this.$storageData.profile.restBranchID : 0,
          empTypeID: this.$storageData.profile.empTypeID,
          city: this.$storageData.profile.city,
        })
        .then((result) => {
          this.restaurants = result.data.data;
        });
    },
    getMenuTabType() {
      this.axios.post(this.$loggedRole+"/getMenuTabType").then((result) => {
        this.menuTabArr = result.data.data;
      });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" />
    <div class="card" style="margin-top: -30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <div class="row">
            <div class="col-md-4">
              <label> Choose Restaurant Brand </label>
              <multiselect
                v-model="restaurantID"
                :options="restaurants"
                class="mb-3"
                track-by="restID"
                label="name"
                @input="getAllDiscount()"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <label> Choose Menu Type </label>
              <multiselect
                v-model="menuTabType"
                :options="menuTabArr"
                :show-labels="false"
                label="menuAccessName"
                track-by="enum"
                @input="getAllDiscount()"
              ></multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 30px">
      <div class="col-md-6 col-xl-3">
        <div class="card">
          <div class="card-body">
            <div>
              <h4 class="mb-1 mt-1">
                {{ cardData.totalCoupons }}
                <!-- <span data-plugin="counterup">
                        <countTo :startVal="1000" :endVal="voucherData.totalAmountRedeemed" :duration="2000"></countTo>
                    </span> -->
              </h4>
              <p class="text-muted mb-0">Total Coupons</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xl-3">
        <div class="card">
          <div class="card-body">
            <div>
              <h4 class="mb-1 mt-1">
                {{ cardData.activeCoupons }}
                <!-- <span data-plugin="counterup">
                        <countTo :startVal="1000" :endVal="voucherData.totalAmountRedeemed" :duration="2000"></countTo>
                    </span> -->
              </h4>
              <p class="text-muted mb-0">Active Coupons</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xl-3">
        <div class="card">
          <div class="card-body">
            <div>
              <h4 class="mb-1 mt-1">
                {{ cardData.redeemedCoupons }}
                <!-- <span data-plugin="counterup">
                        <countTo :startVal="1000" :endVal="voucherData.totalAmountRedeemed" :duration="2000"></countTo>
                    </span> -->
              </h4>
              <p class="text-muted mb-0">Redeemed Coupons</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" style="margin-bottom: 15px;" v-if="this.$storageData.login_type == 1">
                <div role="group" class="btn-group">
                  <button
                    type="button"
                    class="btn btn-themeBrown"
                    @click="downloadSample()"
                  >
                    Excel
                  </button>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select
                      style="margin-left: 5px; margin-right: 5px"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                striped
                hover
                outlined
                bordered
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                fixed-header
              >
                <template v-slot:cell(menuType)="data">
                  <span> {{ data.item.menuTabEnum }}</span>
                </template>

                <template v-slot:cell(Validity)="data">
                  <span
                    v-if="data.item.validity == 'Valid'"
                    style="color: green"
                    >{{ data.item.validity }}</span
                  >
                  <span v-else style="color: red">{{
                    data.item.validity
                  }}</span>
                </template>
                <template v-slot:cell(discountInRs)="data">
                  <span v-if="data.item.discountInRs"
                    >₹{{ data.item.discountInRs }}</span
                  >
                  <span v-else> - </span>
                </template>
                <template v-slot:cell(discountPercent)="data">
                  <span>{{ data.item.discountPercent }}%</span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
/* td{
  white-space: normal !important;
} */
</style>

